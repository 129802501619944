import React from 'react';
import PlatformVisibility from './platform-visibility';
import { JIRA } from '@labqube/contexts';

const JiraPlatform = ({ children }) => (
  <PlatformVisibility platforms={[JIRA]}>
    {children}
  </PlatformVisibility>
);

export default JiraPlatform;
