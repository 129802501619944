export const getParticipants = async (userAccountIds) => {
    const params = userAccountIds.reduce((acc, val) => acc += `accountId=${val}&`, '').slice(0, -1);
    return new Promise((resolve, reject) => {
        window.AP.request({
            url: `/rest/api/3/user/bulk?${params}`,
            type: 'GET',
            contentType: 'application/json',
            success: function (response) {
                const users = JSON.parse(response).values;
                resolve(users);
            },
            error: function (response) {
                reject(response);
            },
        });
    });
};
