import React, { useContext, useState } from 'react';
import { defaultContext } from '@tanstack/react-query';
import { Input, Avatar, Lozenge } from '@labqube/components';
import CreateIssueButton from './create-issue-button';
import { useJiraIssue, useJiraUser } from '@labqube/hooks';

import './discussing-action-items.css';
import { useParams } from 'react-router-dom';
import { PlatformContext, WEBEX } from '@labqube/contexts';

const ActionItem = ({ actionItem, onUpdateActionItem, onDeleteActionItem }) => {
  const { platform } = useContext(PlatformContext);
  const { issueData } = useJiraIssue(actionItem.jira_issue_key, defaultContext);

  const handleIssueCreated = async (issue) => {
    await onUpdateActionItem(actionItem._id, {
      jira_issue_id: issue.id,
      jira_issue_key: issue.key,
      assignee_account_id: issue.fields.assignee?.accountId,
    });
  };

  const assigneeAccountId = issueData?.fields?.assignee?.accountId;
  const { userData } = useJiraUser(assigneeAccountId, defaultContext);

  const handleEditClick = async () => {
    const deleteActionItem = await onDeleteActionItem(actionItem.group_id, actionItem._id, actionItem.content);
    if (deleteActionItem) {

      const inputElement = document.getElementById('inputActionItems');
      if (inputElement) {
        inputElement.value = actionItem.content
        inputElement.focus();
      }
    }
  };

  const handleDeleteClick = () => {
    onDeleteActionItem(actionItem.group_id, actionItem._id);
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  const issueTypeIconUrl = issueData?.fields?.issuetype?.iconUrl;
  const jiraBaseUrl = issueData?.self?.split("/rest")[0];
  const issueUrl = `${jiraBaseUrl}/browse/${issueData?.key}`;
  const defaultAvatarUrl = "https://www.hotelbooqi.com/wp-content/uploads/2021/12/128-1280406_view-user-icon-png-user-circle-icon-png.png";
  const userAvatar = userData?.avatarUrls['24x24'] || defaultAvatarUrl;

  return (
    <div className="action-item">
      {
        issueData && (
          <div className="row spaced">
            <div className='row'>
              <img src="https://www.axonius.com/hubfs/Adapter%20Logos/atlassian-jira_adapter.png" alt="Jira Icon" className="jira-icon" />
              <div>
                <div className="issue-title">{issueData?.fields?.summary}</div>
                <div className="row issue-key">
                  <img src={issueTypeIconUrl} alt="Issue Type" className="issue-type-icon" /> {/* Añadido el icono del tipo de problema */}
                  <a href={issueUrl} target="_blank" rel="noopener noreferrer">{issueData?.key}</a>
                </div>
              </div>
            </div>
            <div className='row'>
              <img src={issueData?.fields?.priority?.iconUrl} alt="Priority" className="priority-icon" />
              <div style={{ marginRight: 8 }}>
                <Lozenge text={issueData?.fields?.status?.name} appearance="default" />
              </div>
              <Avatar
                photo={userAvatar}
                size={'small'}
              />
            </div>
          </div>
        )
      }
      <div className="row action-content">
        {issueData ? truncateString(issueData.fields.description?.content[0]?.content[0]?.text ?? 'No description available', 150) : actionItem.content}
      </div>
      <div className="row action-icons">
        {
          !issueData && platform !== WEBEX && (
            <CreateIssueButton
              onIssueCreated={handleIssueCreated}
              summary={actionItem.content}
              issueKey={actionItem.jira_issue_key}
            />
          )
        }

        <i className="fa fa-pencil" onClick={handleEditClick} style={{ marginRight: 5 }}></i>
        <i className="fa fa-trash" onClick={handleDeleteClick} style={{ marginRight: 5 }}></i>
      </div>
    </div>
  );
};


const DiscussingActionItems = ({ groupId, actionItems, onCreateActionItem, onUpdateActionItem, onDeleteActionItem }) => {
  const [newActionItem, setNewActionItem] = useState('');
  const { retrospectiveId } = useParams();

  const handleAddActionItem = (newActionItem) => {
    onCreateActionItem({
      retrospectiveId,
      groupId,
      content: newActionItem,
    });
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (newActionItem.trim() !== '') {
        handleAddActionItem(newActionItem);
        setNewActionItem('');
      }
    }
  };

  return (
    <div style={{ width: '45%', textAlign: 'center', padding: '20px' }}>
      <Input
        id="inputActionItems"
        placeholder={'Write and hit enter'}
        value={newActionItem}
        onChange={e => setNewActionItem(e.target.value)}
        onKeyDown={handleEnterPress}
        style={{ width: '100%', marginRight: '5%', marginBottom: 15, height: '44px', overflowWrap: 'break-word', marginTop: 15 }} />
      {actionItems.map((actionItem, index) => (
        <ActionItem
          key={index}
          actionItem={actionItem}
          onUpdateActionItem={onUpdateActionItem}
          onDeleteActionItem={async (groupId, itemId, content) => {
            if (content) {
              setNewActionItem(content)
            }

            return onDeleteActionItem(groupId, itemId)
          }} />
      ))}
    </div>
  );
};

export default DiscussingActionItems;
