import { Avatar, Spinner } from "@labqube/components";
import React, { useContext } from "react";
import { defaultContext } from '@tanstack/react-query';
import { useParticipants } from '@labqube/hooks';
import { PlatformContext, WEBEX } from "@labqube/contexts";

function ParticipantsLine({ participants, renderModeratorStar }) {
    const { platform } = useContext(PlatformContext);
    const { isLoading, data } = useParticipants({ userAccountIds: participants.map(p => p.account_id) }, defaultContext)

    if (platform === WEBEX) {
        return (
            <div style={{ display: 'flex' }}>
                {participants?.map((user, index) => (
                    <div key={index} className="circle-avatar">
                        <Avatar
                            key={index}
                            photo={'https://ui-avatars.com/api/?name=' + user.name + '&size=24'}
                            status={participants[index].status}
                            size={'small'}
                        />
                        {renderModeratorStar(participants[index])}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div>
            {isLoading ? (
                <Spinner />
            ) : (
                <div style={{ display: 'flex' }}>
                    {data?.map((user, index) => (
                        <div key={index} className="circle-avatar">
                            <Avatar
                                key={index}
                                photo={user.avatarUrls['24x24']}
                                status={participants[index].status}
                                size={'small'}
                            />
                            {renderModeratorStar(participants[index])}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ParticipantsLine;