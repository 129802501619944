import React, { useContext } from 'react';
import { defaultContext } from '@tanstack/react-query';
import { useParticipant } from '@labqube/hooks';
import DiscussingActionItems from './discussing-action-items';
import useActionItems from '../../hooks/useActionItems';
import { useRetrospective } from '../../hooks/useRetrospective';
import { Banner } from '@labqube/components';
import './discussing.css';
import { PlatformContext, WEBEX } from '@labqube/contexts';

const Idea = ({ idea, columns }) => {
  const { data: retrospective } = useRetrospective(idea.retrospective_id);
  const { platform } = useContext(PlatformContext);
  const { data: participant } = useParticipant({ userAccountId: idea.account_id }, defaultContext);
  const column = columns?.find(col => col._id === idea.column_id);
  const borderColor = column ? column.color : '#CCC';

  const retrospectiveParticipant = retrospective.participants.find(p => p.account_id === idea.account_id);

  return (
    <li className="idea" style={{ border: `1px solid ${borderColor}`, padding: '10px', margin: '10px 0', display: 'flex' }}>
      <img
        src={platform === WEBEX ? 'https://ui-avatars.com/api/?name=' + retrospectiveParticipant.name + '&size=24' : participant?.avatarUrls?.['24x24']}
        alt="Avatar"
        style={{
          height: '24px',
          width: '24px',
          borderRadius: '50%',
          marginRight: '10px',
        }}
      />
      <span style={{ textAlign: 'left', wordBreak: 'break-word' }}>{idea.content}</span>
    </li>
  );
};

const Discussing = ({ retrospectiveId, groups, ideasByGroup, currentGroupIndex, onChangeIndex }) => {

  const { data } = useRetrospective(retrospectiveId);
  const { me } = data || {};
  
  const groupsWithIdeas = groups.filter(group => (ideasByGroup[group._id] || []).length > 0);

  // Crear un nuevo array que contenga solo las ideas no agrupadas
  const ungroupedIdeas = ideasByGroup['undefined'] || [];

  // Solo agregar las ideas no agrupadas al final de los grupos si hay ideas no agrupadas
  const allGroups = ungroupedIdeas.length > 0
    ? [...groupsWithIdeas, { _id: 'undefined', name: 'Ungrouped Ideas', description: 'These are the ideas that have not been grouped.' }]
    : [...groupsWithIdeas];


  const { actionItems, createActionItem, updateActionItem, deleteActionItem } = useActionItems(retrospectiveId, allGroups[currentGroupIndex]._id);

  const goToNextGroup = () => {
    let newIndex = currentGroupIndex + 1;
    if (newIndex >= allGroups.length) {
      newIndex = 0;
    }
    onChangeIndex(newIndex);
  };

  const goToPreviousGroup = () => {
    let newIndex = currentGroupIndex - 1;
    if (newIndex < 0) {
      newIndex = allGroups.length - 1;
    }
    onChangeIndex(newIndex);
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <Banner
            title='Navigate through your groups using the arrows and add action items using the input on the right'
            appearance={'INFO'}
            textAlign='center'
        />
      </div>
      <div className='discussing-wrapper'>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3 style={{ wordBreak: 'break-word' }}>{allGroups[currentGroupIndex].name}</h3>
          {me.is_moderator && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={goToPreviousGroup} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}><i className="fas fa-arrow-left"></i></button>
              <span>{currentGroupIndex + 1} of {allGroups.length}</span>
              <button onClick={goToNextGroup} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}><i className="fas fa-arrow-right"></i></button>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '45%', textAlign: 'center', padding: '20px' }}>
            <div>
              {allGroups.map((group, index) => (
                <div key={index} style={{ display: currentGroupIndex === index ? 'block' : 'none' }}>

                  {/* Aquí se muestran las ideas para el grupo actual */}
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {(ideasByGroup[group._id] || []).map((idea, ideaIndex) => (
                      <Idea key={ideaIndex} idea={idea} columns={data.columns}/>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <DiscussingActionItems 
            groupId={allGroups[currentGroupIndex]._id} 
            actionItems={actionItems || []} 
            onCreateActionItem={createActionItem}
            onUpdateActionItem={updateActionItem} 
            onDeleteActionItem={deleteActionItem}
          />      
          </div>
        </div>
    </div>
  );
};

export default Discussing;

