import React, { useContext } from 'react';
import { defaultContext } from '@tanstack/react-query';
import { Button, Loading } from '@labqube/components';
import { useRetrospective } from '../hooks/useRetrospective';
import { useCurrentPlatformUser } from '@labqube/hooks';
import EventsProvider from '../context/provider';
import Retrospective from './retrospective';
import { useNavigate, useParams } from 'react-router-dom';
import { JIRA, PlatformContext } from '@labqube/contexts';

const withUserValidation = (WrappedComponent) => {
    return () => {
        const { platform } = useContext(PlatformContext);

        if (platform !== JIRA) {
            return (
                <EventsProvider>
                    <WrappedComponent />
                </EventsProvider>
            );
        }

        const { retrospectiveId } = useParams();
        const navigate = useNavigate();

        const retrospective = useRetrospective(retrospectiveId);
        const currentJiraUser = useCurrentPlatformUser(defaultContext);

        if (retrospective.isLoading || currentJiraUser.isLoading) {
            return <Loading />;
        }

        const userIsParticipant = retrospective.data?.participants?.some(
            participant => participant.account_id === currentJiraUser.accountId
        );

        if (userIsParticipant) {
            return (
                <EventsProvider>
                    <WrappedComponent />
                </EventsProvider>
            );
        } else {
            const handleReturnToList = () => {
                navigate('/');
            };

            return (
                <div style={{ textAlign: 'center', alignItems: 'center', padding: 100 }}>
                <p>You are not part of the participant list</p>
                    <Button 
                        type={'primary'} 
                        style={{ height: 47, width: 137 }} 
                        onClick={handleReturnToList}
                    >
                        Return to retrospective list
                    </Button>
                </div>
            );
        }
    };
};

const UserValidatedRetrospective = withUserValidation(Retrospective);

export default UserValidatedRetrospective;
