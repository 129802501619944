import React from 'react';

const CreateIssueButton = ({ onIssueCreated, summary, issueKey }) => {

  const handleClick = () => {
    if (window.AP) {
      if (issueKey) {
        window.AP.jira.openIssueDialog(issueKey, function() {});
      } else {
        window.AP.context.getContext((context) => {
          const projectId = context.jira.project.id;
          window.AP.user.getCurrentUser(function(user) { // Obtén la información del usuario actual
            const currentUser = user.atlassianAccountId;
            window.AP.jira.openCreateIssueDialog(
              function(issues) {
                const issue = issues[0];
                if (typeof onIssueCreated === "function") {
                  onIssueCreated(issue);
                }
              },
              {
                pid: projectId,
                issueType: 1,
                fields : {
                  summary: summary,
                  reporter: currentUser, // Usa el ID de cuenta de Atlassian del usuario como reportero
                }
              }
            );
          });
        });
      }
    }
  };

  const buttonStyle = issueKey ? {color: 'green'} : {color: 'gray'};
  const jiraIconStyle = issueKey ? { color: 'blue' } : {};

  return (
    <button onClick={handleClick} style={{background: 'none', border: 'none'}}>
      <i class="fa-brands fa-jira" style={{ ...buttonStyle, ...jiraIconStyle }}></i>
    </button>
  );
};

export default CreateIssueButton;
