import React from 'react';

const WaitingRoom = () => {
  return (
    <div>
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ fontSize: '24px', marginBottom: '10px' }}>
                    ...Waiting for others to join...
                </div>
            </div>
        </div>
    </div>
  );
};

export default WaitingRoom;
