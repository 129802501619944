import {
    useQuery,
    useQueryClient,
    defaultContext,
} from '@tanstack/react-query';
import axios from '../lib/axios';

function useActionItems(retrospectiveId, groupId) {
    const queryClient = useQueryClient();
    const queryKey = ["actionItems", groupId];

    const { data: actionItems = [], isLoading } = useQuery({
        context: defaultContext,
        queryKey,
        queryFn: async () => {
            try {
                const response = await axios.get(`/api/team-plays/retrospective-groups/${groupId}/retrospective-action-items?retrospective_id=${retrospectiveId}`);
                return response.data;
            } catch (e) {
                console.log('handler', e)
            }
        },
        enabled: !!groupId,
    });

    const createActionItem = async ({
        retrospectiveId,
        groupId,
        assigneeAccountId = false,
        content,
        completed = false,
        jiraIssueId
    } = {}) => {
        const response = await axios.post(`/api/team-plays/retrospective-action-items`, {
            retrospective_id: retrospectiveId,
            group_id: groupId,
            assignee_account_id: assigneeAccountId,
            content,
            completed,
            jira_issue_id: jiraIssueId,
        });

        queryClient.invalidateQueries(queryKey);
        
        return response.data;
    };

    const updateActionItem = async (actionItemId, {
        jiraIssueId,
        jiraIssueKey,
        assigneeAccountId,
        ...otherFields
    } = {}) => {
        const response = await axios.put(`/api/team-plays/retrospective-groups/${groupId}/retrospective-action-items/${actionItemId}?retrospective_id=${retrospectiveId}`, {
            jira_issue_id: jiraIssueId,
            jira_issue_key: jiraIssueKey,
            assignee_account_id: assigneeAccountId,
            ...otherFields,
        });

        queryClient.invalidateQueries(queryKey);

        return response.data;
    };

    const deleteActionItem = async (groupId, itemId) => {
        try {
          await axios.delete(`/api/team-plays/retrospective-groups/${groupId}/retrospective-action-items/${itemId}?retrospective_id=${retrospectiveId}`);
          queryClient.invalidateQueries(queryKey);
        } catch (error) {
          console.error('Error al eliminar el elemento de acción:', error);
        }
      };

    return { actionItems, isLoading, createActionItem, updateActionItem, deleteActionItem };
}

export default useActionItems;
