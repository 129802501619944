import React, { useContext } from 'react';
import { defaultContext } from '@tanstack/react-query';
import { Avatar } from '@labqube/components';
import CreateIssueButton from './create-issue-button';
import './closing-action-items.css';

import { useJiraIssue, useJiraUser } from '@labqube/hooks';
import { PlatformContext, WEBEX } from '@labqube/contexts';


const ActionItem = ({ actionItem }) => {

  const { platform } = useContext(PlatformContext);
  const { issueData } = useJiraIssue(actionItem.jira_issue_key, defaultContext);
  const assigneeAccountId = issueData?.fields?.assignee?.accountId;
  const { userData } = useJiraUser(assigneeAccountId, defaultContext);

  const issueTypeIconUrl = issueData?.fields?.issuetype?.iconUrl;
  const jiraBaseUrl = issueData?.self?.split("/rest")[0]; 
  const issueUrl = `${jiraBaseUrl}/browse/${issueData?.key}`;
  const defaultAvatarUrl = "https://www.hotelbooqi.com/wp-content/uploads/2021/12/128-1280406_view-user-icon-png-user-circle-icon-png.png";
  const userAvatar = userData?.avatarUrls['24x24'] || defaultAvatarUrl;

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  return (
    <div className="action-item">
      {
        issueData && (
          <div className="row spaced">
            <div className='row'>
              <img src="https://www.axonius.com/hubfs/Adapter%20Logos/atlassian-jira_adapter.png" alt="Jira Icon" className="jira-icon" />
              <div>
                <div className="issue-title">{issueData?.fields?.summary}</div>
                <div className="row issue-key">
                  <img src={issueTypeIconUrl} alt="Issue Type" className="issue-type-icon" /> {/* Añadido el icono del tipo de problema */}
                  <a href={issueUrl} target="_blank" rel="noopener noreferrer">{issueData?.key}</a>
                </div>
              </div>
            </div>
            <div className='row'>
              <img src={issueData?.fields?.priority?.iconUrl} alt="Priority" className="priority-icon" />
              <div className="issue-status">{issueData?.fields?.status?.name}</div>
              <Avatar
                photo={userAvatar}
                size={'small'}
              />
            </div>
          </div>
        )
      }
      <div className="row action-content">
      {issueData ? truncateString(issueData.fields.description?.content[0]?.content[0]?.text ?? 'No description available', 150) : actionItem.content}
      </div>
      <div className="row action-icons">
        {
          !issueData && platform !== WEBEX && (
            <CreateIssueButton
              issueKey={actionItem.jira_issue_key}
            />
          )
        }
      </div>
    </div>
  );
};

const ClosingActionItems = ({ actionItems }) => {

  if (actionItems.length === 0) {
    return (
      <div style={{ padding: 50, marginRight: 50 }}>
        <p>No action items created for this group</p>
      </div>
    );
  }

  return (
    <div style={{ width: '45%', textAlign: 'center', padding: '20px', marginTop: 5 }}>
      {actionItems.map((actionItem, index) => (
        <ActionItem key={index} actionItem={{ ...actionItem, hasJiraIssue: actionItem.jira_issue_key !== undefined }} />
      ))}
    </div>
  );
};

export default ClosingActionItems;
