import React from 'react';
import ReactDOM from 'react-dom/client';
import '@labqube/components/dist/index.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

const isProduction = process.env.REACT_APP_SENTRY_ENV === 'production';

if(process.env.REACT_APP_SENTRY_ENV && process.env.REACT_APP_SENTRY_ENV !== 'local') {
    Sentry.init({
    dsn: 'https://1adf82b0f0f84383ad07b931cd9fa881@o4505191869054976.ingest.sentry.io/4505191879868416',
    environment: isProduction ? 'production' : 'uat',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: isProduction ? 1.0 : 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: isProduction ? 1.0 : 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: isProduction ? 1.0 : 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
