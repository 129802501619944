import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from '@labqube/components';
import Templates from "./templates";
import './selection.css';

const Selection = () =>  {

    const [redirectToSelection, setRedirectToSelection] = useState(false);

    const handleNext = () => {
        setRedirectToSelection(true);
    };

    if (redirectToSelection) {
        return <Templates />;
    }
  
    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title='New Retrospective'
                breadcrumbs={[{
                    href: '/',
                    text: <Link to={'/'}>Retrospective</Link>
                }, {
                    href: '',
                    text: 'Create'
                }]}
            />
            <div className='selection-dark' style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center', padding: 10, width: 400, height: 150 }}>
                    <Link to={'/retrospectives/new'} style={{ textDecoration: 'none', color: 'black' }}>
                        <div className='option-container' style={{ display: 'flex', alignItems: 'center', border: '2px solid #ccc', borderRadius: 10, padding: 10, justifyContent:"space-between" }}>
                            <img src="/custom.png" alt="custom" style={{ width: 80, marginRight: 10, padding: 10 }} />
                            <div style={{ padding: 10, flex: 1, borderLeft: '2px solid #ccc' }}>
                                <h3>Create from Scratch</h3>
                                <p>You can customize your columns and indicate the participants</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div style={{ textAlign: 'center', padding: 10, width: 400, height: 150 }}>
                    <Link to={'#'} onClick={handleNext} style={{ textDecoration: 'none', color: 'black' }}>
                        <div className='option-container' style={{ display: 'flex', alignItems: 'center',border: '2px solid #ccc', borderRadius: 10, padding: 10, justifyContent:"space-between" }} >
                            <img src="/catalog.png" alt="catalog" style={{ width: 80, marginRight: 10, padding: 10 }} />
                            <div style={{ padding: 10, flex: 1, borderLeft: '2px solid #ccc' }}>
                                <h3>Try out a template</h3>
                                <p>Choose the one that best suits your needs</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Selection;