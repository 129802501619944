import React, { useContext } from 'react';
import { PlatformContext } from '@labqube/contexts';

function PlatformVisibility({ children, platforms }) {
  const { platform } = useContext(PlatformContext);

  if (!platforms.includes(platform)) {
    return null;
  }

  return <>{children}</>;
}

export default PlatformVisibility;
