import {
    useQuery,
    defaultContext,
    useQueryClient
} from '@tanstack/react-query';

import axios from '../lib/axios';
import { getParticipantStatuses } from './utils';
import { useCurrentPlatformUser } from '@labqube/hooks';

function fetchParticipants(retrospectiveId) {
    const request1 = axios.get(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-participants`);
    const request2 = axios.get(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-participants-information`);

    return Promise.all([request1, request2])
        .then(responses => {
            const participants = responses[0].data;
            const participantsInformation = responses[1].data;

            participants.forEach(participant => {
                const match = participantsInformation.find(info =>
                    info.retrospective_id === participant.retrospective_id &&
                    info.account_id === participant.account_id &&
                    info.project_id === participant.project_id &&
                    info.client_key === participant.client_key
                );

                if (match) {
                    participant.name = match.name;
                }
            });

            return getParticipantStatuses(participants);
        });
}

async function fetchColumns(retrospectiveId) {
    const response = await axios.get(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-columns`);
    return response.data;
}

async function fetchIdeas(retrospectiveId) {
    const response = await axios.get(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-ideas`);
    return response.data;
}

async function fetchRetrospective(retrospectiveId) {
    const response = await axios.get(`/api/team-plays/retrospectives/${retrospectiveId}`);
    return response.data;
}

const getGroups = async (retrospectiveId) => {
    const response = await axios.get(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-groups`);
    return response.data;
};

const _createGroup = async (retrospectiveId, groupText) => {
    const response = await axios.post(`/api/team-plays/retrospective-groups`, {
        name: groupText,
        retrospective_id: retrospectiveId,
    });
    return response.data;
};

export function useRetrospective(retrospectiveId) {
    const queryClient = useQueryClient();

    const { accountId, isLoading: isLoadingJiraUser } = useCurrentPlatformUser(defaultContext);

    const { isLoading: isLoadingParticipants, data: participants, error: errorParticipants } = useQuery({
        context: defaultContext,
        queryKey: ['participants', retrospectiveId],
        queryFn: () => {
            return fetchParticipants(retrospectiveId)
        },
        refetchInterval: 30000,
        enabled: !!retrospectiveId,
    });

    const { isLoading: isLoadingColumns, data: columns, error: errorColumns } = useQuery({
        context: defaultContext,
        queryKey: ['columns', retrospectiveId],
        queryFn: () => {
            return fetchColumns(retrospectiveId)
        },
        enabled: !!retrospectiveId,
    });

    const { isLoading: isLoadingGroups, data: groups, error: errorGroups } = useQuery({
        context: defaultContext,
        queryKey: ['groups', retrospectiveId],
        queryFn: () => {
            return getGroups(retrospectiveId)
        },
        enabled: !!retrospectiveId,
    });

    const { isLoading: isLoadingIdeas, data: ideas, error: errorIdeas } = useQuery({
        context: defaultContext,
        queryKey: ['ideas', retrospectiveId],
        queryFn: () => {
            return fetchIdeas(retrospectiveId)
        },
        enabled: !!retrospectiveId,
    });

    const { isLoading: isLoadingRetrospective, data: retrospective, error: errorRetrospective } = useQuery({
        context: defaultContext,
        queryKey: ['retrospective', retrospectiveId],
        queryFn: () => {
            return fetchRetrospective(retrospectiveId)
        },
        enabled: !!retrospectiveId,
    });

    const isLoading = isLoadingParticipants || isLoadingColumns || isLoadingIdeas || isLoadingRetrospective || isLoadingGroups;
    const error = errorParticipants || errorColumns || errorIdeas || errorGroups || errorRetrospective;

    function createIdea(retrospective_id, column_id, content) {
        const payload = { retrospective_id, column_id, content };
        return axios.post('/api/team-plays/retrospective-ideas', payload);
    }

    async function updateIdea(idea, group_id) {
        return axios.put(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-ideas/${idea._id}`, {
            group_id,
        });
    }

    async function deleteIdea(ideaId) {
        return axios.delete(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-ideas/${ideaId}`);
    }

    function createGroup(name) {
        return _createGroup(retrospectiveId, name)
            .then(newGroup => {
                queryClient.invalidateQueries('groups');
                return newGroup;
            });
    };

    async function updateGroupName(groupId, newGroupName) {
        return axios.put(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-groups/${groupId}`, {
            name: newGroupName,
        });
    }

    async function deleteGroup(groupId) {
        return axios.delete(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-groups/${groupId}`);
    }

    const setStage = (newStage) => {
        return axios.put(`/api/team-plays/retrospectives/${retrospectiveId}`, { stage: newStage });
    };

    const setDiscussIndex = (newIndex) => {
        return axios.put(`/api/team-plays/retrospectives/${retrospectiveId}`, { discuss_index: newIndex });
    };

    const setCurrentGroup = (groupId) => {
        return axios.put(`/api/team-plays/retrospectives/${retrospectiveId}`, { current_group: groupId });
    };

    const setModerator = (newModeratorId) => {
        return axios.put(`/api/team-plays/retrospectives/${retrospectiveId}/retrospective-participants/${newModeratorId}`, { is_moderator: true });
    };

    const joinSession = () => {
        return axios.post('/api/team-plays/retrospective-participants', {
            retrospective_id: retrospectiveId,
        });
    };

    const createParticipantInformation = (name) => {
        return axios.post(`/api/team-plays/retrospective-participants-information`, { 
            name: name,
            retrospective_id: retrospectiveId
        });
    };

    if (!retrospectiveId) {
        return {
            isLoading: false,
            data: null,
        };
    }

    return {
        isLoading,
        data: {
            participants,
            columns,
            ideas,
            retrospective,
            groups,
            me: isLoadingJiraUser ? null : participants?.find(p => p.account_id === accountId),
        },
        error,
        createIdea,
        createGroup,
        setStage,
        setModerator,
        setDiscussIndex,
        setCurrentGroup,
        updateIdea,
        deleteIdea,
        updateGroupName,
        deleteGroup,
        joinSession,
        createParticipantInformation,
    };
}