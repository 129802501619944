import React, { useState } from 'react';
import { defaultContext } from '@tanstack/react-query';
import { Input, AvatarList } from '@labqube/components';
import { useJiraUsers } from '@labqube/hooks';

import './participant-search.css';

const ParticipantsSearch = ({ onSelect }) => {
    const [search, setSearch] = useState('');
    const { isLoading, data } = useJiraUsers({ search }, defaultContext);

    const usersResult = isLoading ? [] : data.map(user => {
        return {
            name: user.displayName,
            photo: user.avatarUrls['24x24'],
        }
    })

    const selectParticipant = (index) => {
        onSelect(data[index]);
        setSearch('');
    };

    return (
        <div id='participant-search-container'>
            <p>Participants:</p>
            <Input
                value={search}
                placeholder='Type to search participants'
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
            />
            {
                usersResult?.length > 0 && (
                    <div id='participant-results'>
                        <AvatarList size={'small'} avatares={usersResult} onClick={selectParticipant} />
                    </div>
                )
            }
        </div>
    )
}

export default ParticipantsSearch;