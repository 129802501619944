import { AvatarGroup, Spinner } from "@labqube/components";
import React from "react";
import { defaultContext } from '@tanstack/react-query';
import { useParticipants } from '@labqube/hooks';

function ParticipantsGroup({ participants }) {
    const { isLoading, data } = useParticipants({ userAccountIds: participants }, defaultContext)
    
    const avatares = isLoading ? [] : data.map(user => {
        return {
            name: user.displayName,
            photo: user.avatarUrls['24x24'],
        };
    })

    return (
        <div>
            {isLoading ? (
                <Spinner />
            ) : (
                <AvatarGroup size="small" avatares={avatares} />
            )}
        </div>
    );
}

export default ParticipantsGroup;