import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Banner, Input } from '@labqube/components';
import Idea from '../idea';
import { useRetrospective } from '../../hooks/useRetrospective';
import './grouping.css';

const CollapsibleGroup = ({ retrospectiveId, group, onDeleteGroup, onEditeGroup }) => {

    const { data } = useRetrospective(retrospectiveId);
    const { me } = data || {};

    const [isExpanded, setIsExpanded] = useState(false);
    const ideaRefs = useRef([]);

    useEffect(() => {
        ideaRefs.current = ideaRefs.current.slice(0, group.ideas.length);
    }, [group.ideas]);

    const calculateTop = (index) => {
        if (index === 0) return '0px';

        let totalHeight = 0;
        for (let i = 0; i < index; i++) {
            totalHeight += ideaRefs.current[i]?.clientHeight || 0;
            totalHeight -= 10;
        }
        return `${-totalHeight}px`;
    };

    const calculateContainerHeight = () => {
        if (isExpanded) return 'auto';
    
        if (ideaRefs.current[group.ideas.length - 1]) {
            return `${ideaRefs.current[group.ideas.length - 1].clientHeight + 5 * group.ideas.length + (group.ideas.length * 10)}px`;
        }
    
        return 'auto';
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <h3 style={{ margin: 0, textAlign: 'left', wordBreak: 'break-word' }}>{group.name}</h3>
                {me.is_moderator && (
                    <div style={{ display: 'flex', marginTop: 5 }}>
                        <i
                            className="fas fa-pencil-alt"
                            style={{
                                color: 'black',
                                cursor: 'pointer',
                                marginLeft: 10,
                                marginRight: 5,
                            }}
                            onClick={() => onEditeGroup(group._id)}
                        />
                        <i
                            className="fas fa-trash"
                            style={{
                                color: 'black',
                                cursor: 'pointer'
                            }}
                            onClick={() => onDeleteGroup(group._id)}
                        />
                    </div>
                )}
            </div>
            <p style={{ flex: 1, marginBottom: 10, textAlign: 'left', opacity: '70%' }}>{group.ideas.length} ideas in this group</p>
            <div
                onClick={() => setIsExpanded(!isExpanded)}
                style={isExpanded ? { position: 'relative', cursor: 'pointer' } : { overflow: 'hidden', position: 'relative', cursor: 'pointer', height: calculateContainerHeight(), minHeight: 0 }}>
                {group.ideas.map((idea, index) => (
                    <div 
                        key={idea._id}
                        ref={el => ideaRefs.current[index] = el}
                        style={isExpanded ? { position: 'relative', bottom: 'initial' } : {
                            position: 'relative',
                            top: calculateTop(index),
                            width: '100%',
                            background: 'white'
                        }}
                    >
                        <Idea idea={idea} color={idea.color} />
                    </div>
                ))}
            </div>
        </div>
    );
};

   

const Grouping = ({ retrospectiveId, columns, ideasByColumn }) => {

    const { data, createGroup, setCurrentGroup, updateIdea, updateGroupName, deleteGroup } = useRetrospective(retrospectiveId);
    const { me, groups } = data || {};
    const currentGroup = groups.find(group => group._id === data?.retrospective?.current_group);

    const [showUntitledGroup, setShowUntitledGroup] = useState(!!currentGroup);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editedTitle, setEditedTitle] = useState(currentGroup?.name ?? 'untitled group');
    const [isDragAndDropMode, setIsDragAndDropMode] = useState(!!currentGroup);
    const [selectedIdeas, setSelectedIdeas] = useState([]); 

    const [ideasList, setIdeasList] = useState([]);
    const [ideasGroup, setIdeasGroup] = useState([]);

    const [columnHeight, setColumnHeight] = useState('100vh');

    useEffect(() => {
        const setColumnHeights = () => {
            const screenHeight = window.innerHeight;
            const offsetHeight = 0;
            const dynamicHeight = screenHeight - offsetHeight;
    
            setColumnHeight(`${dynamicHeight}px`);
        };
    
        setColumnHeights();
        window.addEventListener('resize', setColumnHeights);

        return () => {
            window.removeEventListener('resize', setColumnHeights);
        };
        
    }, []);

    useEffect(() => {
        let list = [];
        const groupedIdeas = {};
    
        columns.forEach((column) => {
            if (ideasByColumn[column._id]) {
                ideasByColumn[column._id].forEach((idea) => {
                    const groupId = idea.group_id;
    
                    if (groupId) {
                        if (!groupedIdeas[groupId]) {
                            const group = groups.find(group => group._id === groupId);
                            const groupName = group?.name;
                            const groupIds = group?._id;
                    
                            groupedIdeas[groupId] = {
                                group: {
                                    _id: groupIds,
                                    name: groupName,
                                    ideas: [],
                                },
                            };
                        }
    
                        groupedIdeas[groupId].group.ideas.push({
                            ...idea,
                            color: column.color,
                        });
                    } 
                    
                    list.push({
                        ...idea,
                        color: column.color,
                    });
                });
            }
        });
        
        setIdeasList(list.filter(i => !i.group_id));
        setIdeasGroup(Object.values(groupedIdeas));

        if (currentGroup) {
            const currentGroupIdeas = list.filter(idea => idea.group_id === currentGroup._id);
            setSelectedIdeas(currentGroupIdeas);
            setShowUntitledGroup(true);
            setIsDragAndDropMode(true);
            setEditedTitle(currentGroup?.name ?? 'untitled group');
        } else {
            setSelectedIdeas([]);
            setEditedTitle('untitled group');
        }
    }, [columns, ideasByColumn, currentGroup, groups]);
    

    const handleCreateGroup = () => {
        if (me.is_moderator) {
            setShowUntitledGroup(true);
            createGroup('untitled group').then((newGroup) => {
                const groupId = newGroup._id;
                setCurrentGroup(groupId);
                setIsDragAndDropMode(true);
                setEditedTitle('untitled group');
            });
        }
    };   

    const handleEditTitle = () => {
        if (me.is_moderator) {
            setIsEditingTitle(true);
        }
    };

    const handleTitleChange = (event) => {
        setEditedTitle(event.target.value);
    };

    const handleTitleBlur = () => {
        setIsEditingTitle(false);
        handleSaveGroupName();
    };

    const handleSaveGroupName = async () => {
        await updateGroupName(currentGroup._id, editedTitle);
    };

    const handleTitleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setIsEditingTitle(false);
            handleSaveGroupName();
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const sourceColumnId = result.source.droppableId;
        const destinationColumnId = result.destination.droppableId;

        if (sourceColumnId === 'left-column' && destinationColumnId === 'droppable' && currentGroup) {
            const draggedIdea = ideasList[result.source.index];
            updateIdea(draggedIdea, currentGroup._id);

            setIdeasList((prevIdeas) => {
                const newIdeas = [...prevIdeas];
                newIdeas.splice(result.source.index, 1);
                return newIdeas;
            });
        }
        else if (sourceColumnId === 'droppable' && destinationColumnId === 'left-column') {
            const draggedIdea = selectedIdeas[result.source.index];
            updateIdea(draggedIdea, null);

            setSelectedIdeas((prevSelectedIdeas) => {
                const newSelectedIdeas = [...prevSelectedIdeas];
                newSelectedIdeas.splice(result.source.index, 1);
                return newSelectedIdeas;
            });
        }
    }

    const handleDeleteGroup = async (groupId) => {
        const result = window.confirm('Do you want to delete this group?');

        if (result) {
            await deleteGroup(groupId);
        }
    };

    const handleEditeGroup = (groupId) => {
        if (!me.is_moderator) {
            return;
        }

        const group = groups.find((group) => group._id === groupId);
        if (group) {
            setCurrentGroup(groupId);
            setIsDragAndDropMode(true);
            setEditedTitle(group.name);
        }
    };

    return (
        <>
            <div style={{ width: '100%' }}>
                <Banner
                    title="Group similar ideas together"
                    appearance={'INFO'}
                    textAlign='center'
                />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <div style={{ display: 'flex' }} id='grouping-container'>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <Droppable droppableId="left-column">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} style={{ minHeight: 350 }}>
                                    {/* Columna izquierda */}
                                    <p style={{ textAlign: 'center' }}>Your ideas</p>
                                    <div style={{ flex: 1, display: 'flex', width: '100%', justifyContent: 'left', maxHeight: columnHeight }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            {ideasList.map((idea, index) => (
                                                <Draggable draggableId={idea._id} index={index} key={idea._id}>
                                                    {(provided) => (
                                                        <div
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                marginTop: 10
                                                            }}
                                                        >
                                                            <Idea idea={idea} color={idea.color} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div style={{ flex: 1, marginRight: 15 }}>
                        {showUntitledGroup &&
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {isEditingTitle ? (
                                        <Input
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                            type="text"
                                            value={editedTitle}
                                            onChange={handleTitleChange}
                                            onBlur={handleTitleBlur}
                                            onKeyPress={handleTitleKeyPress}
                                            autoFocus
                                        />
                                    ) : (
                                        <p style={{ wordBreak: 'break-word' }}>{editedTitle}</p>
                                    )}
                                    {me.is_moderator && (
                                        <>
                                            <i
                                                className="fas fa-pencil-alt"
                                                style={{
                                                    color: 'black',
                                                    cursor: 'pointer',
                                                    marginLeft: 10,
                                                    marginRight: 5,
                                                }}
                                                onClick={handleEditTitle}
                                            />
                                            <i
                                                className="fas fa-trash"
                                                style={{
                                                    color: 'black',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        }
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {/* Columna central */}
                                    <div style={{ textAlign: 'center', border: '2px dashed #ccc', padding: '5px', backgroundColor: 'rgba(200, 200, 200, 0.1)', marginTop: 5, height: '100%' }}>
                                        {isDragAndDropMode ? (
                                            <>
                                                {selectedIdeas.length === 0 ? (
                                                    <p style={{ padding: 100 }}>Drag & drop ideas here to group them</p>
                                                ) : null}
                                                {selectedIdeas.map((idea, index) => (
                                                    <Draggable draggableId={idea._id} index={index} key={idea._id}>
                                                        {(provided) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                    marginTop: 10
                                                                }}
                                                            >
                                                                <Idea idea={idea} color={idea.color} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </>
                                        ) : (
                                            <p style={{ padding: 100 }}>Waiting for the moderator to create a group</p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div style={{ flex: 1, overflowY: 'auto', maxHeight: '100vh', marginLeft: 15 }}>
                        {/* Columna derecha */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ textAlign: 'center' }}>
                                    Your groups
                                </p>
                                {me.is_moderator && (
                                    <i
                                        class="fa-solid fa-plus"
                                        onClick={handleCreateGroup}
                                        style={{
                                            marginLeft: 50,
                                            color: 'black',
                                            cursor: 'pointer'
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', minHeight: '350px' }}>
                            <>
                            {groups.length === 0 && me.is_moderator ? (
                                <p style={{ padding: 100 }}>Click on the  +  icon to create a new group</p>
                            ):null}
                            {ideasGroup.map((group) => (
                                <CollapsibleGroup
                                    retrospectiveId={retrospectiveId} 
                                    key={group.group.name} 
                                    group={group.group} 
                                    onDeleteGroup={handleDeleteGroup} 
                                    onEditeGroup={handleEditeGroup}
                                />
                            ))}
                            </>
                        </div>
                    </div>

                </div>
            </DragDropContext >
        </>
    );
};

Grouping.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    })).isRequired
};

export default Grouping;
