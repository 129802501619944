import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import io from "socket.io-client";
import EventsContext from '.';

const ENDPOINT = process.env.REACT_APP_API_BASE_URL || window.location.origin;
const jwt = window.localStorage.getItem('labqube-jwt');

const EventsProvider = ({ children }) => {
    const eventsRef = useRef({});
    const socketRef = useRef();
    const { retrospectiveId } = useParams();

    const subscribe = (key, handler, id) => {
        if (!eventsRef.current[key]) {
            eventsRef.current[key] = [];
        }
        if (!eventsRef.current[key].some(h => h.id === id)) {
            eventsRef.current[key].push({ handler, id });
        }
    };

    const unsubscribe = (key, id) => {
        if (eventsRef.current[key]) {
            eventsRef.current[key] = eventsRef.current[key].filter(h => h.id !== id);
        }
    };

    const runHandlersForKey = (key, value) => {
        if (eventsRef.current[key]) {
            eventsRef.current[key].forEach(({ handler }) => handler(value));
        }
    };

    useEffect(() => {
        socketRef.current = io(ENDPOINT, {
            transports: ['websocket'],
            path: "/api/team-plays/socket.io",
            query: {
                retrospective_id: retrospectiveId,
                jwt: jwt,
            },
            extraHeaders: {
                'Authorization': jwt
            }
        });

        socketRef.current.on('connect', () => {
            console.log('Connected to server');
        });

        socketRef.current.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        socketRef.current.on('ideas:change', (idea) => {
            runHandlersForKey('ideas:change', idea);
        });

        socketRef.current.on('participants:change', (idea) => {
            runHandlersForKey('participants:change', idea);
        });

        socketRef.current.on('groups:change', (idea) => {
            runHandlersForKey('groups:change', idea);
        });

        socketRef.current.on('actionItems:change', (idea) => {
            runHandlersForKey('actionItems:change', idea);
        });

        socketRef.current.on('retrospective:change', (idea) => {
            runHandlersForKey('retrospective:change', idea);
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, [retrospectiveId]);

    return (
        <EventsContext.Provider value={{ subscribe, unsubscribe }}>
            {children}
        </EventsContext.Provider>
    );
};

export default EventsProvider;
