import { Button, Input } from '@labqube/components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRetrospective } from '../hooks/useRetrospective';

const PromptName = () => {
    const navigate = useNavigate()

    const [localStorageRetrospectiveId, setLocalStorageRetrospectiveId] = useState(window.localStorage.getItem('retrospectiveId') || '');
    const [name, setName] = useState('');

    const { createParticipantInformation } = useRetrospective(localStorageRetrospectiveId);

    const navigateAndCleanStorage = async () => {
        if (localStorageRetrospectiveId && name) {
            await createParticipantInformation(name);
            navigate(`/retrospectives/${localStorageRetrospectiveId}`);
            window.localStorage.removeItem('retrospectiveId');
            setLocalStorageRetrospectiveId('');
        }
    }

    return (
        <div style={{
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 100,
            textAlign: 'center'
        }}>
            <Input
                style={{ marginRight: 10 }}
                placeholder='What name do you want to use in this retrospective?'
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                }}
            />
            <Button
                style={{
                    marginTop: 20
                }}
                type="primary" 
                onClick={() => navigateAndCleanStorage()}
            >
                Join Retrospective
            </Button>
        </div>
    )
}

export default PromptName;
