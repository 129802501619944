import {
    useQuery,
    defaultContext,
    useQueryClient,
} from '@tanstack/react-query';

import axios from '../lib/axios';

const useRetrospectives = ({ id } = {}) => {
    const queryClient = useQueryClient();

    const { isLoading, data } = useQuery({ 
        context: defaultContext,
        queryKey: ['retrospectives', id], 
        queryFn: () => {
            return axios.get('/api/team-plays/retrospectives').then(response => {
                if (Array.isArray(response.data)) {
                    return response.data.sort((a, b) => {
                        const dateA = new Date(a.createdAt);
                        const dateB = new Date(b.createdAt);
    
                        return dateB - dateA;
                    });
                }
    
                return response.data;
            });
        } 
    });
    

    function create({ name, columns, participants, editor = false}) {
        return axios.post('/api/team-plays/retrospectives', {
            name,
            columns,
            participants: participants.map(p => p.accountId),
            participants_details: participants,
            editor,
        }).then((response) => {
            queryClient.invalidateQueries({ queryKey: ['retrospectives'] });
            return response;
        });
    }

    function update(retrospectiveId, { name, columns, participants, editor = false}) {
        return axios.put(`/api/team-plays/retrospectives/${retrospectiveId}`, {
            name,
            columns: columns.map(column => ({ _id: column._id, name: column.name, color: column.color })),
            participants: participants.map(p => p.accountId),
            participants_details: participants,
            editor,
        }).then((response) => {
            queryClient.invalidateQueries({ queryKey: ['retrospectives'] });
            queryClient.invalidateQueries({ queryKey: ['participants'] });
            queryClient.invalidateQueries({ queryKey: ['columns'] });
            return response;
        });
    }

    function remove({ id }) {
        return axios.delete(`/api/team-plays/retrospectives/${id}`)
            .then((response) => {
                queryClient.invalidateQueries({ queryKey: ['retrospectives'] });
                return response;
            });
    }

    return {
        isLoading,
        data,
        create,
        update,
        remove,
    }
}

export default useRetrospectives;
