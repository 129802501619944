import {
    useQuery,
    defaultContext,
} from '@tanstack/react-query';

import axios from '../lib/axios';

const useRetrospectiveTemplates = (templateId) => {
    const queryKey = templateId ? ['retrospective_template', { templateId }] : ['retrospective_templates'];

    const { isLoading, data } = useQuery({
        context: defaultContext,
        queryKey,
        queryFn: () => {
            if (templateId) {
                return axios.get(`/api/team-plays/retrospective-templates/${templateId}`).then(response => {
                    return response.data;
                });
            } else {
                return axios.get('/api/team-plays/retrospective-templates').then(response => {
                    return response.data;
                });
            }
        }
    });

    return {
        isLoading,
        data,
    }
}

export default useRetrospectiveTemplates;
