import { HashRouter, Routes, Route } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  defaultContext, 
} from '@tanstack/react-query'

import './App.css';

import List from './components/list';
import RetrospectiveEditor from "./components/retrospective-editor";
import UserValidatedRetrospective from "./components/UserValidatedRetrospective";
import { PlatformProvider } from '@labqube/contexts';
import PromptName from "./components/prompt-name";

const queryClient = new QueryClient({
  context: defaultContext,
})

function App() {
  return (
    <div>
      <PlatformProvider>
        <QueryClientProvider client={queryClient} context={defaultContext}>
          <HashRouter>
            <Routes>
              <Route
                path=""
                element={<List />} 
              />
              <Route
                path="retrospectives/new"
                element={<RetrospectiveEditor />} 
              />
              <Route
                path="retrospectives/prompt-name"
                element={<PromptName />} 
              />
              <Route
                path="retrospectives/:retrospectiveId/edit"
                element={<RetrospectiveEditor />} 
              />
              <Route
                path="retrospectives/:retrospectiveId"
                element={
                  <UserValidatedRetrospective />
                } 
              />
            </Routes>
          </HashRouter>
        </QueryClientProvider>
      </PlatformProvider>
    </div>
  );
}

export default App;
