/**
 * third party dependencies
 */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TwitterPicker } from 'react-color';
import { defaultContext } from '@tanstack/react-query';

/**
 * labqube components
 */
import { PageHeader, Button, Input, Avatar, Loading, Banner } from '@labqube/components';

import './retrospective-editor.css';
import useRetrospectives from '../hooks/useRetrospectives';
import ParticipantsSearch from './participant-search';
import { useRetrospective } from '../hooks/useRetrospective';
import { getParticipants } from '../services/participants.service';
import { useCurrentPlatformUser } from '@labqube/hooks';
import JiraPlatform from '../hoc/jira-platform';
import { JIRA, PlatformContext, WEBEX } from '@labqube/contexts';
import useRetrospectiveTemplates from '../hooks/useRetrospectiveTemplates';

const formWrapperStyle = {
    display: 'flex',
};

const rightContainerStyle = {
    width: '45%',
    marginRight: '5%',
};

const columnWrapperStyle = {
    display: 'flex',
};

const RetrospectiveEditor = () => {
    const { platform, platformApi } = useContext(PlatformContext);

    const leftContainerStyle = platform === JIRA ? {
        width: '45%',
        marginRight: '5%',
    } : {
        width: '100%',
    };

    const [name, setName] = useState('');
    const [columns, setColumns] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [newColumn, setNewColumn] = useState({ name: '', color: '#CCC' });
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const [colorPicker, setColorPicker] = useState({ visible: false, index: -1, x: 0, y: 0 });

    const navigate = useNavigate();
    const { retrospectiveId } = useParams();
    const { create, update } = useRetrospectives();
    const { data, isLoading } = useRetrospective(retrospectiveId);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    // eslint-disable-next-line
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const { accountId } = useCurrentPlatformUser(defaultContext);

    const containerRef = useRef(null);
    const colorPickerRef = useRef(null);

    const location = useLocation();
    const templateId = new URLSearchParams(location.search).get('templateId');
    const { isLoading: templateLoading, data: templateData } = useRetrospectiveTemplates(templateId);

    useEffect(() => {
        const handleMouseDown = (e) => {
            if (colorPicker.visible && containerRef?.current?.contains(e.target) && !colorPickerRef?.current?.contains(e.target)) {
                setColorPicker({ visible: false, index: -1, x: 0, y: 0 });
            }
        };

        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, [colorPicker]);

    useEffect(() => {
        if (templateId) {
            if (!templateLoading && templateData) {
                const { name, columns } = templateData;
                setName(name);
                setColumns(columns.map(column => ({ name: column.name, color: column.color })));
            }
        }
    }, [templateId, templateLoading, templateData]);

    useEffect(() => {
        if (participants && participants.length > 0) {
            // Verifica si hay un moderador en la lista actual de participantes
            const hasModerator = participants.some(p => p.is_moderator);
            if (!hasModerator) {
                // Si no hay moderador, establece el primer participante como moderador
                const newParticipants = [...participants];
                newParticipants[0].is_moderator = true;
                setParticipants(newParticipants);
            }
        }
    }, [participants]);

    useEffect(() => {
        if (!isDataLoaded) {
            return;
        }

        if (accountId !== data.retrospective.account_id) {
            navigate('');
            return;
        }

        async function loadRetro() {
            setName(data.retrospective.name);
            setColumns(data.columns.map(c => c.name));

            const jiraUsers = await getParticipants(data.retrospective.participants);
            setParticipants(jiraUsers);
        }

        loadRetro();
        // eslint-disable-next-line
    }, [isDataLoaded]);

    useEffect(() => {
        if (data && data.columns && data.participants && data.retrospective && accountId) {
            setIsDataLoaded(true);
        }
    }, [data, accountId]);

    useEffect(() => {
        if (!isEditing) {
            return;
        }

        async function loadRetro() {
            setName(data.retrospective.name);
            setColumns(data.columns.map(c => {
                return {
                    _id: c._id,
                    name: c.name,
                    color: c.color,
                };
            }));

            const jiraUsers = await getParticipants(data.retrospective.participants);
            setParticipants(jiraUsers);
        }

        loadRetro();
        // eslint-disable-next-line
    }, [isDataLoaded]);

    useEffect(() => {
        if (data && data.columns && data.participants && data.retrospective) {
            setIsEditing(true);
        }
    }, [data]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedColumns = [...columns];
        const [removed] = reorderedColumns.splice(result.source.index, 1);
        reorderedColumns.splice(result.destination.index, 0, removed);
        setColumns(reorderedColumns);
    }

    function createRetrospective() {
        if (platform === JIRA) {
            const hasModerator = participants.some(p => p.is_moderator);
            if (!hasModerator) {
                alert('Please select a moderator before saving the retrospective!');
                return;
            }
        }

        if (retrospectiveId) {
            return update(
                retrospectiveId,
                {
                    name,
                    columns,
                    participants,
                    editor: true,
                }
            ).then(() => {
                navigate(`/retrospectives/${retrospectiveId}`);
            });
        }

        return create({
            name,
            columns,
            participants: platform === JIRA ? participants : [{
                is_moderator: true,
                account_id: accountId,
                accountId,
            }],
            editor: true,
        }).then((response) => {
            switch (platform) {
                case JIRA:
                    navigate(`/retrospectives/${response.data._id}`);
                    break;
                case WEBEX:
                    const currentUrl = new URL(window.location.href);
                    const domain = currentUrl.origin;
                    const appPath = '/webex/shared?retrospectiveId=' + response.data._id;
                    const shareUrl = domain + appPath;
                    
                    platformApi.setShareUrl(shareUrl, "", "Team Plays Retrospectives")
                    window.location.href = shareUrl;
                    break;
                default:
                    navigate(`/retrospectives/${response.data._id}`);
                    break;
            }
        });
    }

    const openColorPicker = (index, e) => {
        const rect = e.target.getBoundingClientRect();
        setColorPicker({ visible: true, index, x: rect.x, y: rect.y });
    };

    const avatares = participants.map(participant => {
        return {
            name: participant.displayName,
            photo: participant.avatarUrls['24x24'],
        };
    });

    if (isLoading) {
        return (
            <Loading size='large'>
                <p>
                    Loading retrospective...
                </p>
            </Loading>
        )
    }

    const bannerDismissed = localStorage.getItem('bannerDismissed') === 'true';

    const closeBanner = () => {
        setIsBannerVisible(false);
    };

    
    return (
        <div style={{ padding: 41, paddingTop: 0 }} ref={containerRef}>
            <PageHeader
                title={isEditing ? 'Edit Retrospective' : 'New Retrospective'}
                breadcrumbs={ platform === JIRA ? [{
                    href: '/',
                    text: <Link to={'/'}>Retrospective</Link>
                }, {
                    href: '',
                    text: isEditing ? `${name}` : 'Create'
                }] : []}
                action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <JiraPlatform>
                            <Link to={'/'} style={{ width: '100%' }}>
                                <Button type={'default'} style={{ height: 37 }}>
                                    Cancel
                                </Button>
                            </Link>
                        </JiraPlatform>
                        <Button
                            type={'primary'}
                            style={{ height: 37, marginLeft: 10 }}
                            disabled={!name || !columns.length}
                            onClick={() => createRetrospective()}
                        >
                            {isEditing ? 'Save' : 'Create'}
                        </Button>
                    </div>
                }
            />
            {!bannerDismissed && (
                <JiraPlatform>
                    <div style={{ width: '100%' }}>
                        <Banner
                            title={(
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <p style={{ fontWeight: 'normal' }} >If you are not added as a participant then you will not be able to join the session</p>
                                    </div>
                                    {/* eslint-disable-next-line */}
                                    <a
                                        href='#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            closeBanner();
                                            localStorage.setItem('bannerDismissed', 'true');
                                        }}
                                        style={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'right', fontWeight: 'normal' }}
                                    >
                                        Dismiss
                                    </a>
                                </div>
                            )}
                            appearance={'INFO'}
                            textAlign='center'
                        />
                    </div>
                </JiraPlatform>
            )}
            <div style={{ ...formWrapperStyle }}>
                <div style={{ ...leftContainerStyle }}>
                    <p>Name <span className='required'>*</span></p>
                    <Input
                        style={{ marginRight: 10 }}
                        placeholder='An awesome name for your retrospective'
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />

                    <p>Columns <span className='required'>*</span></p>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {columns.map((column, index) => (
                                        <Draggable key={index} draggableId={`${index}`} index={index} style={{ ...columnWrapperStyle }}>
                                            {(provided) => (
                                                <div
                                                    className='draggable-column-item'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Input
                                                        style={{ marginRight: 10 }}
                                                        placeholder='An awesome name for my column'
                                                        value={column.name}
                                                        onChange={(e) => {
                                                            const localColumns = [...columns];
                                                            localColumns[index]._id = column._id;
                                                            localColumns[index].name = e.target.value;
                                                            setColumns(localColumns);
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            backgroundColor: column.color,
                                                            height: 37,
                                                            minHeight: 37,
                                                            width: 37,
                                                            minWidth: 37,
                                                            marginLeft: 10,
                                                            cursor: 'pointer',
                                                            border: '1px solid darkgray',
                                                            boxSizing: 'border-box',
                                                            borderRadius: 3,
                                                        }}
                                                        onClick={(e) => openColorPicker(index, e)}
                                                    />
                                                    {colorPicker.visible && colorPicker.index === index && (
                                                        <div
                                                            ref={colorPickerRef}
                                                            style={{
                                                                position: 'fixed',
                                                                top: colorPicker.y + 30,
                                                                left: platform === JIRA ? colorPicker.x : 80,
                                                                zIndex: 1000
                                                            }}
                                                        >
                                                            <TwitterPicker
                                                                color={column.color}
                                                                triangle={platform === JIRA ? 'top-left' : 'top-right'}
                                                                onChangeComplete={(color) => {
                                                                    const newColumns = [...columns];
                                                                    newColumns[colorPicker.index]._id = column._id;
                                                                    newColumns[colorPicker.index].color = color.hex;
                                                                    setColumns(newColumns);
                                                                    setColorPicker({ visible: false, index: -1, x: 0, y: 0 });
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    <Button type={'default'} style={{ height: 37, marginLeft: 10, marginRight: 10 }} onClick={(e) => {
                                                        setColumns(columns.filter((c, i) => i !== index));
                                                    }}>
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </Button>
                                                    <i className="fas fa-grip-lines" style={{ cursor: 'pointer' }}></i>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Input
                        style={{ marginRight: 10 }}
                        placeholder='Type a name for your column and hit enter to add it'
                        value={newColumn.name}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13 && newColumn.name.trim()) {
                                setColumns([...columns, newColumn]);
                                setNewColumn({ name: '', color: '#CCC' });
                            }
                        }}
                        onChange={(e) => {
                            setNewColumn({ name: e.target.value, color: newColumn.color });
                        }}
                    />
                </div>
                <JiraPlatform>
                    <div style={{ ...rightContainerStyle }}>
                        <ParticipantsSearch onSelect={(user) => {
                            if (participants.find(p => p.accountId === user.accountId)) {
                                return;
                            }

                            setParticipants([...participants, user]);
                        }} />
                        <div style={{ marginTop: 10 }}>
                            {avatares.map((avatar, index) => {
                                return (
                                    <div
                                        className='participant-item'
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(-1)}
                                    >
                                        <Avatar size={'small'} name={avatar.name} photo={avatar.photo} style={{ marginBottom: 5 }} />
                                        <div className="icons-container">
                                            {hoveredIndex === index && (
                                                <i
                                                    className="fas fa-trash"
                                                    onClick={() => {
                                                        setParticipants(
                                                            participants.filter((_, i) => index !== i)
                                                        )
                                                    }}
                                                ></i>
                                            )}
                                            <div style={{ width: '10px' }} />
                                            {participants[index].is_moderator ? (
                                                <i className="fas fa-star star-moderator" style={{ order: -1 }}></i>
                                            ) : (
                                                hoveredIndex === index && (
                                                    <i
                                                        className="fas fa-star"
                                                        onClick={() => {
                                                            const newParticipants = participants.map((p, j) => {
                                                                if (index === j) {
                                                                    return { ...p, is_moderator: true };
                                                                } else if (p.is_moderator) {
                                                                    // Si ya hay un moderador, establece su propiedad is_moderator a false
                                                                    return { ...p, is_moderator: false };
                                                                } else {
                                                                    return p;
                                                                }
                                                            });
                                                            setParticipants(newParticipants);
                                                        }}
                                                        style={{ order: -1 }}
                                                    ></i>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </JiraPlatform>
            </div>
        </div>
    )
}

export default RetrospectiveEditor;
