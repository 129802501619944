/**
 * third party dependencies
 */
import React, { useState, useEffect, useContext } from 'react';
import { defaultContext } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';

/**
 * labqube components
 */
import { Message, PageHeader, Table, Button, Input, Loading, LastUpdateBy } from '@labqube/components';

/**
 * services
 */
//import {deleteTestCase, getTestCases} from '../services/test-cases';

import './list.css';
import useRetrospectives from '../hooks/useRetrospectives';
import ParticipantsGroup from './participants-group';
import { useCurrentPlatformUser } from '@labqube/hooks';
import { PlatformContext, WEBEX } from '@labqube/contexts';
import Selection from './selection';

const List = () => {
    const { platform } = useContext(PlatformContext);

    const [filter, setFilter] = useState('');
    const [retrospectiveStatus, setRetrospectiveStatus] = useState({});
    const [redirectToSelection, setRedirectToSelection] = useState(false);

    const { accountId, isLoading: isLoadingJiraUser } = useCurrentPlatformUser(defaultContext);
    const { isLoading, data, remove } = useRetrospectives({ filter });

    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            const newStatuses = {};

            data.forEach(item => {
                if (item.stage === 'ideation') {
                    newStatuses[item._id] = 'New';
                } else if (item.stage === 'grouping' || item.stage === 'discussing') {
                    newStatuses[item._id] = 'In progress';
                } else if (item.stage === 'closing') {
                    newStatuses[item._id] = 'Completed';
                }
            });

            setRetrospectiveStatus(newStatuses);
        }
    }, [data]);

    const [localStorageRetrospectiveId, setLocalStorageRetrospectiveId] = useState(window.localStorage.getItem('retrospectiveId') || '');

    useEffect(() => {
        if (!platform) {
            return;
        }

        if (localStorageRetrospectiveId) {
            if (platform === WEBEX) {
                navigate('/retrospectives/prompt-name');
            } else {
                navigate(`/retrospectives/${localStorageRetrospectiveId}`);
                window.localStorage.removeItem('retrospectiveId');
                setLocalStorageRetrospectiveId('');
            }
        } 
        /**
         * by default webex will take the user to the retrospective editor
         * if the retrospectiveId was not shared via query params
         */
        else if (platform === WEBEX) {
            navigate('/retrospectives/new');
        }
    }, [localStorageRetrospectiveId, platform, navigate]);

    const retrospectives = data?.map(item => {
        const status = retrospectiveStatus[item._id] || 'New';

        const isUserInParticipants = item?.participants?.indexOf(accountId) >= 0;

        return {
            id: item._id,
            content: {
                name: isUserInParticipants ? (
                    <Link 
                        to={`/retrospectives/${item._id}`}
                        className='link-dark'
                        style={{ color: 'black', wordBreak: 'break-word', display: 'block', marginTop: '5px' }}
                    >
                        {item.name}
                    </Link>
                ) : <span>{item.name}</span>,
                'created at': (
                    <span style={{ color: 'black', wordBreak: 'break-word', display: 'block', marginTop: '5px' }}>
                        <Moment className='moment-dark'>{item.createdAt}</Moment>
                    </span>
                ),
                participants: (
                    <ParticipantsGroup participants={item.participants} />
                ),
                'created by': <LastUpdateBy accountId={item.creator_account_id} queryClientContext={defaultContext} />,
                status: <span className='status' style={{ color: 'black', wordBreak: 'break-word', textTransform: 'capitalize', display: 'block', marginTop: '5px' }}>{status}</span>,
                actions: (
                    <div style={{ display: 'block', marginTop: '5px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {item.account_id === accountId && (
                                <>
                                    {
                                        status !== 'Completed' && (
                                            <Link
                                                to={`/retrospectives/${item._id}/edit`}
                                                className='link-dark'
                                                style={{ textDecoration: 'none', color: 'black' }}
                                            >
                                                <i className="fa-solid fa-pen-to-square clickable"></i>
                                            </Link>
                                        )     
                                    }
                                    &nbsp;
                                    <i
                                        className="fa-solid fa-trash clickable"
                                        onClick={() => deleteItem(item)}
                                    ></i>
                                </>
                            )}
                        </div>
                    </div>
                )
            },
        }
    }) ?? [];

    function deleteItem(item) {
        const result = window.confirm('Do you want to delete this retrospective and its data?');

        if (result) {
            return remove({ id: item._id }).then(() => {
                setRetrospectiveStatus(prevStatus => {
                    const newStatus = { ...prevStatus };
                    delete newStatus[item._id];
                    return newStatus;
                });
            }).catch((e) => {
                console.log(e);
                // TODO https://labqube.atlassian.net/browse/COM-4
            });
        }
    }

    if (isLoading || isLoadingJiraUser) {
        return (
            <Loading size='large'>
                <p>
                    Loading retrospectives...
                </p>
            </Loading>
        )
    }

    const handleNext = () => {
        setRedirectToSelection(true);
    };

    if (redirectToSelection) {
        return <Selection />;
    }

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={'Retrospectives'}
                breadcrumbs={[{
                    href: '',
                    text: 'Retrospectives'
                }]}
                action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input 
                            style={{ marginRight: 10, width: 250 }} 
                            placeholder='Search retrospectives'
                            value={filter}
                            onChange={(e) => {
                                setFilter(e.target.value);
                            }}
                        />
                        {
                            retrospectives.length !== 0 && (
                                <Button type={'primary'} style={{height: 37}} onClick={handleNext}>
                                    Add retrospective
                                </Button>
                            )
                        }
                    </div>
                }
            />
            {
                !retrospectives.length && (
                    <Message
                        title={'No retrospectives to show'}
                        description={'Try creating a new one!'}
                        actionComponent={
                            <Button type="primary" onClick={handleNext}>
                                Let's start!
                            </Button>
                        }
                    />
                )
            }
            {
                retrospectives.length !== 0 && (
                    <Table
                        headers={['Name', 'Created at', 'Participants', 'Created by', 'Status', 'Actions']}
                        columnsWidth={['25%', '25%', '20%', '20%', '10%', '5%']}
                        items={retrospectives}
                        pagination={true}
                    />
                )
                
            }
        </div>
    )
}

export default List;
