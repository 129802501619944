import React, { useMemo, useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useClipboard from 'react-use-clipboard';
import { useQueryClient } from '@tanstack/react-query';
import { useRetrospective } from '../hooks/useRetrospective';
import { useShareUrl } from '@labqube/hooks';
import { Loading, PageHeader, Button, Input, ProgressBar } from '@labqube/components';
import { useEvents } from '../context';
import Ideation from './session/ideation';
import Grouping from './session/grouping';
import Discussing from './session/discussing';
import Closing from './session/closing';
import WaitingRoom from './session/waiting-room';
import ParticipantsLine from './participants-line';
import './retrospective.css';
import { JIRA, PlatformContext, WEBEX } from '@labqube/contexts';

function Retrospective() {
    const stages = ["waiting-room", "ideation", "grouping", "discussing", "closing"];
    const { platform } = useContext(PlatformContext);

    const { retrospectiveId } = useParams();
    const shareUrl = useShareUrl(retrospectiveId);
    const { isLoading, data, error, setStage, setDiscussIndex, setModerator, joinSession} = useRetrospective(retrospectiveId);
    const queryClient = useQueryClient();

    const { subscribe, unsubscribe } = useEvents();
    const navigate = useNavigate();

    const [isPopupVisible, setPopupVisible] = useState(false);

    const [isCopied, setCopied] = useClipboard(shareUrl, {
        copiedDuration: 3000,
        disableClipboardAPI: true,
    });

    useEffect(() => {
        if (platform === WEBEX) {
            joinSession().then(() => {
                queryClient.invalidateQueries({ queryKey: ['participants'] });
                queryClient.invalidateQueries({ queryKey: ['retrospective'] });
            })
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const ids = {
            'ideas:change': 'ideasChangeId',
            'participants:change': 'participantsChangeId',
            'retrospective:change': 'retrospectiveChangeId',
            'groups:change': 'groups',
            'actionItems:change': 'actionItems',
        };

        subscribe('ideas:change', () => {
            queryClient.invalidateQueries({ queryKey: ['ideas'] });
        }, ids['ideas:change']);

        subscribe('participants:change', () => {
            queryClient.invalidateQueries({ queryKey: ['participants'] });
        }, ids['participants:change']);

        subscribe('retrospective:change', () => {
            queryClient.invalidateQueries({ queryKey: ['retrospective'] });
        }, ids['retrospective:change']);

        subscribe('groups:change', () => {
            queryClient.invalidateQueries({ queryKey: ['groups'] });
        }, ids['groups:change']);

        subscribe('actionItems:change', () => {
            queryClient.invalidateQueries({ queryKey: ['actionItems'] });
        }, ids['actionItems:change']);

        return () => {
            unsubscribe('ideas:change', ids['ideas:change']);
            unsubscribe('participants:change', ids['participants:change']);
            unsubscribe('retrospective:change', ids['retrospective:change']);
            unsubscribe('groups:change', ids['groups:change']);
            unsubscribe('actionItems:change', ids['actionItems:change']);
        };
    }, [queryClient, subscribe, unsubscribe]);


    const { retrospective, me } = data || {};
    const { stage: currentStage } = retrospective || {};

    const ideasByColumn = useMemo(() => {
        if (!data) {
            return {};
        }

        return data?.ideas?.reduce((acc, idea) => {
            const columnId = idea.column_id;

            if (!acc[columnId]) {
                acc[columnId] = [];
            }

            acc[columnId].push(idea);

            return acc;
        }, {});
    }, [data]);

    const ideasByGroup = useMemo(() => {
        if (!data) {
            return {};
        }

        return data?.ideas?.reduce((acc, idea) => {
            const groupId = idea.group_id || undefined;

            if (!acc[groupId]) {
                acc[groupId] = [];
            }

            acc[groupId].push(idea);

            return acc;
        }, {});
    }, [data]);

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const handleNext = () => {
        const currentIndex = stages.indexOf(currentStage);
        if (currentIndex < stages.length - 1) {
            if (currentStage === "ideation" && (!ideasByColumn || Object.keys(ideasByColumn).length === 0)) {
                return;
            }
            setStage(stages[currentIndex + 1]);
        }
    };

    const handlePrevious = () => {
        const currentIndex = stages.indexOf(currentStage);
        if (currentIndex > 0) {
            setStage(stages[currentIndex - 1]);
        }
    };

    const handleLeaveSession = () => {
        navigate('/');
    };

    const getCurrentCountForProgressBar = () => {
        switch (currentStage) {
            case "waiting-room":
                return 0;
            case "ideation":
                return 1;
            case "grouping":
                return 2;
            case "discussing":
                return 3;
            case "closing":
                return 4;
            default:
                return 0;
        }
    }

    const renderStage = () => {
        switch (currentStage) {
            case "waiting-room":
                return <WaitingRoom />;
            case "ideation":
                return <Ideation columns={data.columns} ideasByColumn={ideasByColumn} />;
            case "grouping":
                return <Grouping retrospectiveId={retrospectiveId} columns={data.columns} ideasByColumn={ideasByColumn} />;
            case "discussing":
                return <Discussing retrospectiveId={retrospectiveId} groups={data.groups} ideasByGroup={ideasByGroup} currentGroupIndex={data.retrospective.discuss_index} onChangeIndex={setDiscussIndex} />;
            case "closing":
                return <Closing retrospectiveId={retrospectiveId} groups={data.groups} ideasByGroup={ideasByGroup} />;
            default:
                return null;
        }
    };

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const handleCopy = () => {
        setCopied();
        togglePopup();
    };

    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength - 3) + '...';
    };

    const handleSelectModerator= (participantId) => {
        setModerator(participantId);
    };

    const renderModeratorStar = (user) => {
        const isCurrentUserModerator = me?.is_moderator;

        if (isCurrentUserModerator) {
            return (
                <div className="moderator-container">
                    <div
                        className={`circle-icon ${user.is_moderator ? 'moderator' : ''}`}
                        onClick={() => handleSelectModerator(user._id)}
                    ></div>
                </div>
            );
        }

        if (user.is_moderator) {
            return (
                <div className="moderator-container">
                    <div
                        className={`circle-icon moderator ${isCurrentUserModerator ? 'selectable' : ''}`}
                        onClick={() => handleSelectModerator(user._id)}
                    ></div>
                </div>
            );
        }
    };

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                style={{ wordBreak: 'break-word' }}
                title={data.retrospective.name}
                breadcrumbs={ platform === JIRA ? [
                    {
                        href: <Link to={'/'}>Retrospectives</Link>
                    },
                    {
                        href: '',
                        text: `${data?.retrospective?.name}`
                    }
                ] : []}
                action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {platform === JIRA && currentStage && (
                            <>
                                <div className="popup-container">
                                    {isCopied && <span style={{ color: 'black', marginRight: 10 }}>Copied to clipboard!</span>}
                                    <Button type={'default'} onClick={togglePopup} style={{ marginRight: 10, height: 37 }}>
                                        <i class="fa-solid fa-share"></i>
                                    </Button>
                                    {isPopupVisible && (
                                        <div className="popup">
                                            <div className="popup-content">
                                                <Input value={truncateText(shareUrl, 50)} style={{ width: '200px' }} readOnly />
                                                <Button type={'default'} onClick={handleCopy} style={{ marginLeft: 10, wordBreak: 'normal' }}>Copy</Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {stages.indexOf(currentStage) > 0 && currentStage !== "closing" && me?.is_moderator && (
                            <Button
                                type={'default'}
                                style={{ height: 37, marginRight: 10, minWidth: 137 }}
                                onClick={handlePrevious}
                                disabled={!me?.is_moderator}
                            >
                                Back to {stages[stages.indexOf(currentStage) - 1]}
                            </Button>
                        )}
                        {stages.indexOf(currentStage) < stages.length - 1 && me?.is_moderator && (
                            <Button
                                type={'primary'}
                                style={{ height: 37, minWidth: 137, width: 'auto' }}
                                onClick={handleNext}
                                disabled={(!me?.is_moderator && currentStage === 'waiting-room') || (currentStage !== 'waiting-room' && (!me?.is_moderator || !ideasByColumn || Object.keys(ideasByColumn).length === 0))}
                            >
                                Go to {stages[stages.indexOf(currentStage) + 1]}
                            </Button>
                        )}
                        {currentStage === "closing" && platform !== WEBEX && (
                            <Button
                                type={'primary'}
                                style={{ height: 37, marginRight: 10, width: 137 }}
                                onClick={handleLeaveSession}
                            >
                                Leave session
                            </Button>
                        )}
                    </div>
                }
            />
            <ProgressBar total={4} current={getCurrentCountForProgressBar()} />
            <div style={{display: 'flex', justifyContent: 'space-between', padding: 10}}>
                <div>
                    
                </div>
                <div>
                    <ParticipantsLine participants={data?.participants} renderModeratorStar={renderModeratorStar}/>
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 5 }}>
                <div style={{ width: '100%' }}>
                    {renderStage()}
                </div>
            </div>
        </div>
    );
}

export default Retrospective;
